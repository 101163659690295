<template>
  <div id="privacy" style="height: 60px"></div>
    <TopNavigation title="" hideSecondNavbar="true" />
    <div class="TermsPrivacy">
        <div class="row">
            <div class="col-12 lblTitle text-center py-4">Privacy Notice</div>

            <div class="col-12 text-center pb-4">
                <div>UPDATED: April 10, 2023</div>
                <div>EFFECTIVE: April 10, 2023</div>
            </div>

            <div class="col-12 lblContent">
                <div class="lblSubTitle">Purpose</div>
                <div class="mb-3">At SignOn, privacy is a priority. The purpose of this document is to set out how SignOn and its affiliates ("us," "our," or "we") collect, use, store, or otherwise process personal information about customers and other individuals (collectively "you") who access or use our websites, including SignOn.my, our mobile applications, our web client or professional client, and/or any of our other websites, products, or services that link to this Privacy Notice (the "Services"). By using our Services, you understand that we will collect and use your personal information as described in this Privacy Notice.</div>
                <div class="mb-3">In some cases, we may process your personal information pursuant to an agreement with a third party organization. In those cases, the terms of that agreement may govern how we process your personal information. If you believe a third party organization has asked us to process your personal information on their behalf, please consult with them in the first instance as they will be responsible for how we process your information. This Privacy Notice ("Notice") does not apply to any third-party websites and apps that you may use, including those to which we link in our Services. You should review the terms and policies for third-party websites and apps before clicking on any links.</div>
                <div class="mb-3">SignOn's core product and Services help users create, complete, and show the validity of digital or electronic transactions, such as electronically signing a contract for mobile phone services or placing a digital signature on a loan application. As part of our Services, users want us to collect and record information that helps the parties prove the validity of the transactions, such as the names of the persons who are involved in the transactions and the devices those persons use.</div>
                <div class="mb-3">We recommend that you read this Notice in full to ensure you are fully informed about the manner in which we collect, use, store, or otherwise process your personal information as well as your privacy rights. However, if you want to skip to a particular section of this Notice, please refer to the table of contents below.</div>
                <div class="ps-3">
                    <div><a href="#sec1" class="isLink text-primary">1. Collection of Personal Information</a></div>
                    <div><a href="#sec2" class="isLink text-primary">2. Use of Personal Information</a></div>
                    <div><a href="#sec3" class="isLink text-primary">3. Personal Information Sharing</a></div>
                    <div><a href="#sec4" class="isLink text-primary">4. Retention of Personal Information</a></div>
                    <div><a href="#sec5" class="isLink text-primary">5. Your Choices</a></div>
                    <div><a href="#sec6" class="isLink text-primary">6. Children's Privacy</a></div>
                    <div><a href="#sec7" class="isLink text-primary">7. Your Privacy Rights</a></div>
                    <div><a href="#sec8" class="isLink text-primary">8. How We Protect Your Personal Information</a></div>
                    <div><a href="#sec9" class="isLink text-primary">9. Changes to This Privacy Notice</a></div>
                    <div><a href="#sec10" class="isLink text-primary">10. How to Contact Us</a></div>
                </div>
            </div>

            <div class="col-12 lblContent">
                <div class="lblSubTitle" id="sec1">1. Collection of Personal Information</div>
                <div class="mb-3">You have choices about whether you visit our websites, install our apps, or provide personal information to us. However, if you do not provide us with certain personal information, you may not be able to use some parts of our Services. For example, if you do not adopt an electronic signature, then you will not be able to sign certain electronic documents on our Service. For choices and rights you may have, please see <a href="#sec5" class="text-primary isLink">Sections 5</a> and <a href="#sec7" class="text-primary isLink">Section 7</a> of this Notice.</div>
                <div class="mb-3"><span class="fw-bold">Personal Information We Collect from You.</span> You provide us with personal information about yourself when you:</div>
                <div class="mb-3">  
                  <ul>
                    <li>Register or log in to your account.</li>
                    <li>Start, sign, or review an electronic document.</li>
                    <li>Create or edit your user profile.</li>
                    <li>Contact customer support.</li>
                    <li>Comment on our blogs or in community forums.</li>
                    <li>Participate in surveys, sponsored events, sweepstakes, or when you sign up for newsletters.</li>
                  </ul>
                </div>
                <div class="mb-3">You also provide us with personal information about others when you use parts of our Services, such as when you:</div>
                <div class="mb-3">
                  <ul>
                    <li>Start or participate in an electronic transaction, such as a folder within SignOn.</li>
                    <li>Add others as a member to an existing account.</li>
                    <li>Leave comments.</li>
                    <li>Refer friends.</li>
                  </ul>
                </div>
                <div class="mb-3">Your main choice for this type of personal information is simply not providing it, such as by not creating a profile or not leaving a comment in a blog. For other choices you may have, please see <a href="#sec5" class="text-primary isLink">Section 5</a> of this Privacy Notice.</div>
                <div class="mb-3">Examples of the categories of personal information you may provide are:</div>
                <div class="mb-3">
                  <ul>
                    <li><span class="fw-bold">Identifiers and contact information.</span> This includes your name, email address, mailing address, phone number, or electronic signature.</li>
                    <li><span class="fw-bold">Commercial information.</span> This includes billing and payment information (e.g., credit card number, expiration date, visual cryptogram), products or services purchased.</li>
                    <li><span class="fw-bold">Geolocation.</span> This includes physical location</li>
                  </ul>
                </div>
                <div class="mb-3"><span class="fw-bold">Personal Information We Collect Automatically.</span> We may automatically collect personal information from you and your devices when you use our Services, including when you visit our websites or apps without logging in. For choices you may have on what information we automatically collect, please see Section ­­5 of this Privacy Notice.</div>
                <div class="mb-3">The categories of personal information we may automatically collect includes:</div>
                <div class="mb-3">
                  <ul>
                    <li>
                      <span class="fw-bold">Device, Usage Information, and Transactional Data.</span> We collect personal information about how you use our Services and the devices (e.g., computers, mobile phones, tablets) you use to access our Services. This may include, but is not limited to, the following:
                      <ul>
                        <li>IP address.</li>
                        <li>Precise geolocation information that you allow our apps to access (usually from your mobile device).</li>
                        <li>Unique device identifiers and device attributes, such as operating system and browser type.</li>
                        <li>Usage data, such as web log data, referring and exit pages and URLs, platform type, number of clicks, domain names, landing pages, pages and content viewed and the order of those pages, the amount of time spent on particular pages, the date(s) and time(s) you used our Services, the frequency of your use of our Services, error logs, and other related information.</li>
                        <li>Transactional data, such as names and email addresses of parties to a transaction, subject line, history of actions that individuals take in connection with a transaction (e.g., review, sign, enable features) and personal information about those individuals or their devices, such as name, email address, IP address, and authentication methods.</li>
                      </ul>
                    </li>
                    <li>
                      <span class="fw-bold">Cookies and Related Technologies.</span> We use cookies, which are text files containing small amounts of information that are downloaded on your device, or related technologies, such as web beacons, local shared objects and tracking pixels, to collect and/or store information.
                    </li>
                  </ul>
                </div>
                <div class="mb-3"><span class="fw-bold">Information We Collect from Other Sources.</span> Subject to applicable law, we may collect personal information about you from others, such as:</div>
                <div class="mb-3">
                  <ul>
                    <li><span class="fw-bold">Third-Party Sources.</span> Examples of third-party sources include marketers, partners, researchers, affiliates (like companies under common ownership or control of SignOn), service providers, and others where they are legally allowed to share your personal information with us. For example, if you register for our Services on another website, the website may provide your personal information to us.</li>
                    <li><span class="fw-bold">Other Customers.</span> Other customers may give us your personal information. For example, if a customer wants you to sign an electronic document in our Services, he or she will give us your email address and name.</li>
                    <li><span class="fw-bold">Combining Personal Information from Different Sources.</span> We may combine the personal information we receive from other sources with personal information we collect from you (or your device) and use it as described in this Notice.</li>
                  </ul>
                </div>
                <div class="mb-3"><span class="fw-bold">Personal Information We Collect & Process on Behalf of Customers.</span> When our business customers use certain Services, we generally process and store limited personal information on their behalf as a data processor. For example, in the context of SignOn eSignature, when a customer uploads contracts or other documents for review or signature, we act as a data processor and process the documents on the customer's behalf and in accordance with their instructions. In those instances, the customer is the data controller and is responsible for most aspects of the processing of the personal information. If you have any questions or concerns about how personal information is processed in these cases, including how to exercise your rights as a data subject, you should contact the customer (either your employer or the individual or entity requesting your signature). If we receive any rights requests concerning instances where we act as data processor, we will forward your query on to the relevant customer.</div>
            </div>

            <div class="col-12 lblContent">
                <div class="lblSubTitle" id="sec2">2. Use of Personal Information</div>
                <div class="mb-3">In general, and subject to applicable law, including consent (as required), we may use your personal information to provide, fix, and improve our Services, develop new Services, and market our companies and their products and Services. Examples of how we use the personal information we process include, but are not limited to, the following:</div>
                <div class="mb-3">
                  <ul>
                    <li>Provide you with and collect payment for the products and Services and products you request.</li>
                    <li>Create your account and manage your relationship with us (e.g., communicating with you, providing you with requested information).</li>
                    <li>Send you records of our relationship, including for purchases or other events.</li>
                    <li>Market features, products, or special events using email or phone or send you marketing communications about third party products and services we think may be of interest to you.</li>
                    <li>Record details about transactions involving electronic documents (e.g., who initiated, viewed, or signed the documents; signers' IP addresses; timestamps).</li> 
                    <li>Run sweepstakes, contests, and refer-a-friend programs.</li>
                    <li>Choose and deliver content and tailored advertising, and support the marketing and advertising of our Services.</li>
                    <li>Create and review data about our users and how they use our Services.</li>
                    <li>Test changes in our Services and develop new features and products.</li>
                    <li>Fix problems you may have with our Services, including answering support questions, customer education and training, and resolving disputes.</li>
                    <li>Manage the Services platform, including support systems and security.</li>
                    <li>Prevent, investigate and respond to fraud, unauthorized access to or use of our Services, breaches of terms and policies, or other wrongful behavior.</li>
                    <li>Comply with legal obligations.</li>
                    <li>Meet legal retention periods.</li>
                    <li>Establish, exercise, or defend our rights in legal claims.</li>
                  </ul>
                </div>
                <div class="mb-3"><span class="fw-bold">Other Uses.</span> We may combine the personal information we collect ("aggregate") or remove pieces of personal information ("de-identify") to limit or prevent identification of any particular user or device to help with goals like research and marketing. Once such information has been aggregated and anonymized so that it is no longer considered personal information under applicable data protection law, this Notice does not apply.</div>
                <div class="mb-3"><span class="fw-bold">Lawful Basis for Processing Your Personal Information.</span> If European data protection law applies and where SignOn acts as a data controller, our lawful basis for collecting and using the personal information described in this Notice will depend on the type of personal information concerned and the specific context in which we collect or use it.</div>
                <div class="mb-3">We normally collect or use personal information only where we have your consent to do so, where we need the personal information to perform a contract with you, or where the processing is in our legitimate interests and not overridden by your data protection interests or fundamental rights and freedoms. In some cases, we may have a legal obligation to collect or retain personal information or may need the personal information to protect your vital interests or those of another person. For example, when we:</div>
                <div class="mb-3">
                  <ul>
                    <li>Use personal information to create and manage an account, we do so in order to provide you with relevant Services and perform our contract with you.</li>
                    <li>Gather and record data associated with use of a digital certificate or digital signature, we do so to comply with regulations.</li>
                    <li>Use names and email addresses for email marketing purposes, we do so with your consent (which you can withdraw at any time) or, where permitted under applicable law, on the basis of our legitimate interests.</li>
                    <li>Gather usage data and analyze it to improve our Services or ensure the security of our websites, we do so based on our legitimate interest in safeguarding and improving our Services.</li>
                  </ul>
                </div>
                <div class="mb-3">If you have questions about or need further information concerning the lawful basis on which we collect and use your personal information, please contact us using the contact details provided in <a href="#sec10" class="text-primary isLink">Section 10</a> of this Notice.  Note that in situations where SignOn acts as a processor, it is our customer who determines the appropriate legal basis associated with processing activities, and queries about the applicable lawful basis should be directed to them.</div>
            </div>

            <div class="col-12 lblContent">
                <div class="lblSubTitle" id="sec3">3. Personal Information Sharing</div>
                <div class="mb-3">Subject to applicable law, including consent (as required), we may share personal information as follows:</div>
                <div class="mb-3">We share personal information as follows:</div>
                <div class="mb-3">
                  <ul>
                    <li><span class="fw-bold">Service Providers.</span> We share your personal information with service providers we use to support our Services. These companies provide services like intelligent search technology, intelligent analytics, advertising, authentication systems, bill collection, fraud detection, and customer support. We have contracts with our service providers that address the safeguarding and proper use of your personal information.</li>
                    <li><span class="fw-bold">Affiliates.</span> We may share your personal information with other companies under common ownership or control with SignOn. These companies use your personal information as described in this Notice.</li>
                    <li><span class="fw-bold">Marketing Partners.</span> We may share your personal information with sponsors of events, webinars, or sweepstakes for which you register, or other parties with whom we may engage in joint marketing activities.</li>
                    <li><span class="fw-bold">Public or Government Authorities.</span> We may share your personal information to comply with our legal obligations, regulations, or contracts, or to respond to a court order, administrative, or judicial process, such as a subpoena, government audit, or search warrant where we are legally compelled to do so. We also may share your information when there are threats to the physical safety of any person, violations of SignOn policies or other agreements, or to protect the legal rights of third parties, including our employees, users, or the public.</li>
                    <li><span class="fw-bold">Corporate Transactions.</span> Your personal information may be disclosed or transferred to relevant third parties in the event of, or as part of the due diligence for, any proposed or actual  reorganization, sale, merger, consolidation, joint venture, assignment, transfer, or other disposition of all or part of our business, assets, or stock (including in connection with any bankruptcy or similar proceeding). If a corporate transaction occurs, we will provide notification of any changes to the control of your information, as well as choices you may have.</li>
                    <li><span class="fw-bold">Consent.</span> We may share your personal information in other ways if you have asked us to do so or have given consent. For example, with your consent, we post user testimonials that may identify you.</li>
                  </ul>
                </div>
                <div class="mb-3">Your personal information may also be shared as described below:</div>
                <div class="mb-3">
                  <ul>
                    <li><span class="fw-bold">Other SignOn users.</span> When you allow others to access, use, or edit content in your account, we share that content with them. For example, if you send a folder to others for review or signature, we make the contents of the folder available to them.</li>
                    <li><span class="fw-bold">Third Parties.</span> When you make a payment to another user within our Services, we share your payment method details with the third-party payment processor selected by you.</li>
                    <li><span class="fw-bold">Public Information.</span>
                      <ul>
                        <li><span class="fw-bold">User-Generated Content.</span> When you comment on our blogs or in our community forums, this information may also be read, collected, and used by others.</li>
                        <li><span class="fw-bold">Profile Information.</span> When you create a SignOn profile, other SignOn users can view your profile information. If you would like to make this information private, please visit your account settings.</li>
                      </ul>
                    </li>
                    <li><span class="fw-bold">Your Employer or Organization.</span> When you create an account or user role with an email address assigned to you as an employee, contractor or member of an organization (e.g., yourname@youremployer.com or yourname@nonprofit.org), that organization (if it is a SignOn customer with certain features) can find your account and take certain actions that may affect your account.</li>
                  </ul>
                </div>
            </div>

            <div class="col-12 lblContent">
                <div class="lblSubTitle" id="sec4">4. Retention of Personal Information</div>
                <div class="mb-3">We keep your personal information for no longer than necessary for the purposes for which it is processed. The length of time for which we retain personal information depends on the purposes for which we collected and use it and/or as required to comply with applicable laws as set out in our data retention policy and information handling standards. Generally, this means we retain your personal information to comply with any retention or statutory limitations or for purposes of performing a contract with you. Where there are technical limitations that prevent deletion or anonymization, we safeguard personal information and limit active use of it.</div>
            </div>

            <div class="col-12 lblContent">
                <div class="lblSubTitle" id="sec5">5. Your Choices</div>
                <div class="mb-3">This section describes many of the actions you can take to change or limit the collection, use, storage, or other processing of your personal information.</div>
                <div class="mb-3">
                  <ul>
                    <li><span class="fw-bold">Profile.</span> You are not required to fill out a profile. If you do, you can access and review this personal information. If any personal information is inaccurate or incomplete, you can make changes in your account settings.</li>
                    <li><span class="fw-bold">Marketing Messages.</span> You can opt out of email marketing messages we send you by clicking on the "unsubscribe" link in the email message. Please note that we may send you one message to confirm you want to opt out. If you are a registered user of our Services, or if you have engaged in transactions with us, we may continue to send transactional or relationship messages (e.g., signing notifications or account notifications) after you opt out of marketing messages.</li>
                    <li><span class="fw-bold">Cookies and Other Related Technology.</span> You can decline cookies through your browser settings. However, if you decline cookies, you may not be able to use some parts of our Services.  Please note we do not recognize or respond to browser-initiated Do Not Track signals.</li>
                    <li><span class="fw-bold">Device and Usage Information.</span> If you do not want us to see your device location, you can turn off location sharing on your device, change your device privacy settings, or decline to share location on your browser.</li>
                    <li><span class="fw-bold">Closing Your Account.</span> If you wish to close your account, please log in to your account and edit your plan.</li>
                    <li><span class="fw-bold">Complaints.</span> We are committed to resolving valid complaints about your privacy and our collection, use, storage, or other processing of your personal information. For questions or complaints regarding our data use practices or this Notice, please contact us at <a href="mailto: privacy@signon.my" class="text-primary isLink">privacy@signon.my</a>.</li>
                  </ul>
                </div>
                
            </div>

            <div class="col-12 lblContent">
                <div class="lblSubTitle" id="sec6">6. Children's Privacy</div>
                <div class="mb-3">Our Services are not designed for and are not marketed to people under the age of 18 or such other age designated by applicable law ("minors"). We do not knowingly collect or ask for personal information from minors. We do not knowingly allow minors to use our Services. If you are a minor, please do not use our Services or send us your personal information. We delete personal information that we learn is collected from a minor without verified parental consent. </div>
            </div>

            <div class="col-12 lblContent">
                <div class="lblSubTitle" id="sec7">7. Your Privacy Rights</div>
                <div class="mb-3">You may have certain rights related to your personal information, subject to local data protection laws, as described in more detail below. To exercise any of these rights, please contact us at <a href="mailto: privacy@signon.my" class="text-primary isLink">privacy@signon.my</a>.</div>
                <div class="mb-3">
                  <ul>
                    <li>You can <span class="fw-bold">access</span> and review personal information associated with your account at any time via your preferences setting.</li>
                    <li>You also can <span class="fw-bold">request</span> the following information: how we collect and use your personal information and why; the categories of personal information involved; the categories of recipients of your personal information; how we received your personal information and its source; our business purpose for using your personal information; and how long we use or store your personal information or the manner in which we determine relevant retention periods.</li>
                    <li>You have a right to <span class="fw-bold">correct</span> inaccurate personal information about you, and you should notify us immediately if you believe the personal information we hold about you is inaccurate, incomplete, or out-of-date.</li>
                    <li>In certain situations, you can ask that we <span class="fw-bold">erase</span> or stop using your personal information, object to or restrict the use of your personal information, or <span class="fw-bold">export</span> your personal information to another controller. </li>
                    <li>Where we rely on your consent to process your personal information, you have the right to decline consent and/or, if provided, to <span class="fw-bold">withdraw consent</span> at any time. This will not affect the lawfulness of processing prior to the withdrawal of your consent. At any time, you can request that we stop using your personal information for direct marketing purposes. See <a href="#sec5" class="text-primary isLink">Section 5</a> (Your Choices) of this Notice for more information on your choices.</li>
                    <li>If you are unsatisfied with our response to your <span class="fw-bold">complaint</span>, you have a right to raise questions or complaints with your local data protection authority at any time.</li>
                  </ul>
                </div>
                <div class="mb-3">If you make a request to exercise the rights referenced above, we will require you to provide certain information for identity verification purposes. If you have an account with us, we may verify you through your login of your account. If you do not have an account with us, we may require you to provide additional information from which we can confirm your identity. You may authorize an agent to make a request to us on your behalf and we will verify the identity of your agent or authorized legal representative by either seeking confirmation from you or documents that establish the agent's authorization to act on your behalf.</div>
                <div class="mb-3">Certain personal information may be exempt from such requests under applicable law. We need certain types of personal information so that we can provide the product and Services to you. If you ask us to delete it, you may no longer be able to access or use our product and Services.</div>
                <div class="mb-3">If you wish to exercise these rights, please contact us at <a href="mailto: privacy@signon.my" class="text-primary isLink">privacy@signon.my</a>.</div>
            </div>

            <div class="col-12 lblContent">
                <div class="lblSubTitle" id="sec8">8. How We Protect Your Personal Information</div>
                <div class="mb-3">We have implemented appropriate technical, physical, and organizational measures to protect your personal information from misuse or accidental, unlawful, or unauthorized destruction, loss, alteration, disclosure, acquisition, or access, as well as all other forms of unlawful processing. To achieve this, we have developed and implemented an Information Security Management System and other sub-policies and guidelines relating to the protection of your personal information. For example, our staff is permitted to access customer personal information only to the extent necessary to fulfill the applicable business purpose(s) and to perform their job, subject to confidentiality obligations.</div>
                <div class="mb-3">To further enhance the protection of your personal information, we have implemented the following security measures:</div>
                <div class="mb-3">  
                  <ul>
                      <li><strong>Amazon S3 File Storage: </strong>Uploaded files are securely stored in Amazon S3, utilizing server-side encryption with Amazon S3-managed keys (SSE-S3) for protection.</li>
                      <li><strong>Secrets and Password Management: </strong>Sensitive credentials such as database and email passwords are securely stored in Amazon Secret Manager, restricted to application backend access. Automatic secrets rotation is disabled.</li>
                      <li><strong>Secure Communication: </strong>Encryption is enforced through HTTPS, though HTTP traffic is not redirected to HTTPS. Amazon RSA 2048 M03 SSL Certificates are used, and digital signing SSL certificates generated by Let’s Encrypt are updated quarterly.</li>
                      <li><strong>Database Security: </strong>Access to the RDS database is strictly controlled via AWS Security Groups, permitting only application instances to connect. RDS encryption is enabled for additional security.</li>
                      <li><strong>Google OAuth Protocol: </strong>Only essential scopes—such as profile, email, openid, and drive.readonly—are requested. Tokens are revoked upon sign-out and stored exclusively within sessions, avoiding databases, files, logs, or URLs.</li>
                      <li><strong>Activity Logging: </strong>Submission activities, including file access from Google Drive and data uploads, are logged in AWS CloudWatch. Logs are maintained for a duration of 8 days.</li>
                  </ul>
                </div>
              </div>

            <div class="col-12 lblContent">
                <div class="lblSubTitle" id="sec9">9. Changes to This Privacy Notice</div>
                <div class="mb-3">We may amend this Notice to reflect changes in the law, our companies, our Services, our data processing practices, or advances in technology. Our use of the personal information we collect is subject to the Privacy Notice in effect at the time such personal information is used. Depending on the type of change, we may notify you of the change by posting on this page.</div>
            </div>

            <div class="col-12 lblContent">
                <div class="lblSubTitle" id="sec10">10. How to Contact Us</div>
                <div class="mb-3">For questions or complaints regarding our use of your personal information or this Notice, please contact us at <a href="mailto: privacy@signon.my" class="text-primary isLink">privacy@signon.my</a>.</div>
            </div>


            <div class="col-12 py-4 text-center footerDesc" @click="navigateToHome">Back to Home</div>
        </div>
    </div>

    <div class="row">
        <div class="col-12 footer">
            <Footer />
        </div>
    </div>
</template>

<script>
import TopNavigation from '@/components/TopNavigation.vue'
import Footer from '@/components/Footer.vue'

export default {
    name: 'PrivacyPolicy',
    components: { TopNavigation, Footer },
    setup () {
      const navigateToHome = () => {
          window.location.href = window.location.origin + '/#home'
      }

      return { navigateToHome }

    }
}
</script>

<style>
.footer {
    margin-top: 80px;
    margin-bottom: 0px;
    padding: 0px 0px 0px 0px;
    background-color: var(--navbar-light);
    border-top-color: var(--toolbar-hr);
}

.TermsPrivacy {
    max-width: 1000px;
    margin: 0px auto 20px auto;
    border-radius: 10px;
}

.lblTitle {
    font-size: 24px;
    font-weight: 600;
    line-height: 1.43;
    color: var(--section-title);
    font-family: "Inter-Medium", sans-serif;
}

.lblSubTitle {
    font-size: 18px;
    font-weight: 600;
    line-height: 1.43;
    color: var(--section-title);
    font-family: "Inter-Medium", sans-serif;
    padding-bottom: 12px;
}

.lblContent {
    color: var(--section-title);
    margin: 0 0 40px 0;
    font-size: 17px;
    font-family: "Inter-Medium", sans-serif;
    font-weight: 400;
    line-height: 1.43;
    text-align: justify;
    text-justify: inter-word;
}
</style>